<template>
  <div class="w-full block relative">
    <div
      class="
        max-w-lg
        mx-auto
        bg-white
        shadow-lg
        rounded
        px-10
        py-10
        w-full
        relative
      "
    >
      <div class="z-20 flex flex-col items-center justify-start">
        <Text
          size="xl"
          weight="semibold"
          color="gray-700"
          :content="title"
          class="text-center uppercase"
        />
        <span
          class="text-sm text-gray-400 text-center mb-10 mt-4"
		  v-if="title !== 'Login'"
          v-html="description"
        ></span>
		<span
          class="text-sm text-gray-400 text-center mb-10 mt-4"
		  v-else
        >Don't have an account? Create a <a class="text-primary underline font-semibold" @click="$emit('goRegister')">profile</a> now</span>
        <div v-for="form in forms" :key="form.label" class="w-full mb-8">
          <Input
            :type="form.type"
            v-model:value="form.value"
            :label="form.label"
            class="w-full"
            :id="form.id"
            :btnText="form.type === 'password' ? 'Forgot password?' : null"
            :btnAction="!form.isNotForgot ? () => $emit('resetPassword') : null"
            :onEnter="onEnter"
			:helpText="form.description"
          />
        </div>
        <Button
          variant="primary"
          :content="btnText"
          :is-loading="isLoading"
          class="
            px-16
            py-4
            font-semibold
            text-md
            shadow-lg
            bg-primary
            text-white
            w-full
          "
          :onClick="action"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import VideoView from "../../../molecules/Content/VideoViewWithModal/VideoViewWithModal.vue";
import Input from "../../../molecules/Inputs/Component/Component.vue";
import Button from "../../../atoms/Button/Button.vue";

/**
 - Use it to show a contact form with overlapped images
 **/
export default {
  components: {
    Button,
    Text,
    VideoView,
    Input,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    forms: {
      type: Array,
      default: () => [],
    },
    btnText: {
      type: String,
      default: "",
    },
    action: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onEnter(event) {
      if (event?.target?.id === "email")
        document.getElementById("password")?.focus();
      else this.action();
    },
  },
};
</script>
