<template>
  <Container :customClass="'overflow-auto'">
    <Header
      :back-action="() => $emit('onBack')"
      @goHome="() => $emit('goHome')"
    />
    <LoginForm
      class="mt-10 sm:mt-20"
      v-bind="form"
      :action="() => $emit('onSubmit')"
      @resetPassword="() => $emit('resetPassword')"
      @goRegister="() => $emit('goRegister')"
      :isLoading="isLoading"
    />
    <div class="max-w-3xl mx-auto space-y-2 mt-16">
      <Text
        v-for="text in texts"
        size="sm"
        color="gray-200"
        :key="text"
        v-html="text"
        class="text-center"
      />
    </div>
  </Container>
</template>

<script>
import Container from "../../atoms/Containers/FullWidthBannerContainer/FullWidthBannerContainer.vue";
import Header from "../../organisms/Headers/Simple/Simple.vue";
import LoginForm from "../../organisms/FormLayouts/LoginForm/LoginForm.vue";
import Text from "../../atoms/Text/Text.vue";

/**
 - Use it to show a Login template
 **/
export default {
  components: {
    Container,
    Header,
    LoginForm,
    Text,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    texts: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
