<template>
  <Template
    v-bind="{
      ...params,
      isLoading,
    }"
    @onSubmit="() => onLogin()"
    @onBack="() => $router.go(-1)"
    @goRegister="() => $router.push('/register')"
    @resetPassword="() => $router.push('/reset-password')"
    @goHome="() => $router.push('/')"
  />
</template>
<script>
import Template from "../components/templates/Login/Login";
import { c } from "@/components/constants.js";

export default {
  components: {
    Template,
  },

  methods: {
    async onLogin() {
      console.log(this.params, "forms");
      const forms = this.params.form.forms;
      if (!forms[0]?.value) {
        this.actions.alert.showError({
          message: "Please insert email address!",
        });
        return false;
      }
      if (!forms[1]?.value) {
        this.actions.alert.showError({ message: "Please insert password!" });
        return false;
      }
      const params = { email: forms[0]?.value, password: forms[1].value };
      this.isLoading = true;
      const res = await this.actions.login(params);
      if (this.state.currentUser?.id) {
        this.actions.alert.showSuccess({ message: "Logged In Successfully" });
        this.$router.push("/dashboard");
      }
      this.isLoading = false;
      console.log(res, "res");
    },
  },
  data() {
    return {
      params: c.login,
      isLoading: false,
    };
  },
};
</script>
